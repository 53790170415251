#pade-search-results table {
    color: #333;
    font-family: Helvetica, Arial, sans-serif;
    border-collapse:
    collapse; border-spacing: 0;
    width: 100%;
}

#pade-search-results td, th {
    border: 1px solid transparent; /* No more visible border */
    height: 30px;
    transition: all 0.3s;  /* Simple transition for hover effect */
    padding: 5px;
}

#pade-search-results th {
    background: #DFDFDF;  /* Darken header a bit */
    font-weight: bold;
}

#pade-search-results td {
    background: #FAFAFA;
    text-align: left;
    vertical-align: top;
}

#pade-search-results .modal-body {
  overflow-x: auto;
}